<template>
    <div v-if="user" v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab :to="{ name: 'userGeneralInfo', params: { user } }">
                General Information
            </v-tab>
            <v-tab :to="{ name: 'userDocuments', params: { user } }">
                Documents
            </v-tab>
            <v-tab
                v-if="readPermissions"
                :to="{ name: 'userPermissions', params: { user } }"
            >
                Permissions
            </v-tab>
            <v-tab
                v-if="readPermissions"
                :to="{ name: 'userlogs', params: { user, id } }"
            >
                Logs
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-title class="py-2" ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-avatar size="46" class="mx-2">
                    <v-img
                        v-if="user.profilePicURL"
                        :src="user.profilePicURL"
                        :alt="user.name"
                    />
                    <v-icon size="48" v-else>mdi-account-circle</v-icon>
                </v-avatar>
                <span v-if="user">{{ user.name }}</span>
                <v-spacer />
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="openDialog">
                            Update User
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-dialog
                    v-if="user"
                    :retain-focus="false"
                    v-model="userDialog"
                    persistent
                    max-width="600px"
                >
                    <UserForm
                        v-if="userDialog"
                        title="User Information"
                        :createForm="false"
                        @closeDialog="closeDialog"
                        :cardDialog="false"
                        :user="this.userToUpdate"
                        :image="this.user.profilePicURL"
                        :originalUser="this.originalUser"
                        @replaceUser="this.replaceUser"
                        @removeUser="this.removeUser"
                    />
                </v-dialog>
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import UserForm from '@/components/Users/UserForm'
import { mapActions } from 'vuex'

export default {
    name: 'User',
    components: {
        UserForm,
    },
    props: {
        id: String,
        user: {},
    },
    data() {
        return {
            height: 0,
            userDialog: false,
            userToUpdate: null,
            originalUser: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            idProject: null,
            currentUser: JSON.parse(localStorage.getItem('userId')),
            readPermissions: false,
        }
    },
    inject: {
        closeDetail: {
            from: 'closeDetail',
            default() {
                return null
            },
        },
    },
    updated() {
        this.onResize()
    },
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()

        const readPermissions = user.permissions.find(
            x => x == 'readPermissions'
        )
        if (readPermissions) {
            this.readPermissions = true
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
            this.closeDetail(false)
        },
        ...mapActions(['replaceUserAction', 'removeUserAction']),
        openDialog: function() {
            const userToUpdate = this.user
            this.userToUpdate = JSON.parse(JSON.stringify(userToUpdate))
            this.originalUser = JSON.parse(JSON.stringify(userToUpdate))
            this.userDialog = true
        },
        closeDialog: function() {
            this.userDialog = false
        },
        replaceUser: function(id, user) {
            this.replaceUserAction({ id, user })
            this.user.name = user.name
            this.user.profilePicURL = user.profilePicURL
            this.user.language = user.language
            this.user.role = user.role
            this.user.phone = user.phone
            this.user.phone2 = user.phone2
            this.user.email = user.email
            this.user.permissions = user.permissions
            this.user.signCode = user.signCode
            this.user.process = user.process
        },
        removeUser: function(id) {
            this.removeUserAction({ id })
            this.$router.replace({ name: 'noUser' })
        },
    },
}
</script>
